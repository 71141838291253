import "./App.css";
import Layout from "./Layout";
import LayoutLeftBar from "./LayoutLeftBar";
import ScannerPage from "./pages/ScannerPage";
import MyTemplatesPage from "./pages/MyTemplatesPage";
import LoginPage from "./pages/loginPage";
import RegisterPage from "./pages/registerPage";
import ConfirmationPage from "./pages/ConfirmationPage";
import { Route, Routes } from "react-router-dom";
import { UserContextProvider } from "./Context/UserContext";
import { FormContextProvider } from "./Context/FormContext";
import { EditScanContextProvider } from "./Context/EditScanContext";
import { LeftBarContextProvider } from "./Context/LeftBarContext";
import { EditJobPostContextProvider } from "./Context/EditJobPostContext";
import { ApplicationContextProvider } from "./Context/ApplicationContext";
import EditTemplate from "./pages/EditTemplate";
import Membership from "./pages/Membership";
import ScanHistoryPage from "./pages/ScanHistoryPage";
import EditScan from "./pages/EditScanPage";
import IndexPage from "./pages/IndexPage";
import RequireAuth from "./RequireAuth";
import Authenticate from "./Authenticate";
import DashboardPage from "./pages/DashboardPage";
import TermsPage from "./pages/TermsPage";
import PrivacyPage from "./pages/PrivacyPage";
import DemoPage from "./pages/DemoPage";
import JobPage from "./pages/JobPage";
import ForgotPage from "./pages/ForgotPage";
import ResetPage from "./pages/ResetPage";
import AdminPage from "./pages/AdminPage";
import BenefitsPage from "./pages/BenefitsPage";
import JobPostPage from "./pages/JobPostPage";
import SettingsPage from "./pages/SettingsPage";
import ApplyPage from "./pages/ApplyPage";

function App() {
  return (
    <UserContextProvider>
      <Authenticate />
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<IndexPage />} />
          <Route
            path="/demo"
            element={
              <FormContextProvider>
                <DemoPage />
              </FormContextProvider>
            }
          />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/forgot-password" element={<ForgotPage />} />
        <Route path="/reset-password/:token" element={<ResetPage />} />
        <Route path="/confirmation/:token" element={<ConfirmationPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        {/* <Route path="/company/:company/job/:id" element={
          <ApplicationContextProvider>
            <ApplyPage/>
          </ApplicationContextProvider>
        } /> */}

        <Route
          element={
            <RequireAuth>
              <LeftBarContextProvider>
                <LayoutLeftBar />
              </LeftBarContextProvider>
            </RequireAuth>
          }
        >
          <Route
            path="/scan"
            element={
              <FormContextProvider>
                <ScannerPage />
              </FormContextProvider>
            }
          />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/history" element={<ScanHistoryPage />} />
          <Route path="/resumes" element={<MyTemplatesPage />} />
          {/* <Route path="/membership" element={<Membership/>} /> */}
          <Route path="/settings" element={<SettingsPage />} />
          {/* <Route path="/benefits" element={<BenefitsPage/>} /> */}
          <Route path="/jobs" element={<JobPage />} />
          {/* <Route path="/recruit/job" element={
            <EditJobPostContextProvider>
              <JobPostPage/>
            </EditJobPostContextProvider>
          } /> */}
          <Route path="/resume" element={<EditTemplate />} />
          <Route path="/resume/:id" element={<EditTemplate />} />
          <Route
            path="/scan/:id"
            element={
              <EditScanContextProvider>
                <EditScan />
              </EditScanContextProvider>
            }
          />
          <Route path="/admin" element={<AdminPage />} />
        </Route>
      </Routes>
    </UserContextProvider>
  );
}

export default App;

export default function PrivacyPage() {
  return (
    <div className="privacy-terms-page">
      <h1>PRIVACY POLICY</h1>

      <p>Last updated September 09, 2023</p>

      <p>
        This privacy notice for Skillglance.com (&quot;we,&quot; &quot;us,&quot;
        or &quot;our&quot;), describes how and why we might collect, store, use,
        and/or share (&quot;process&quot;) your information when you use our
        services (&quot;Services&quot;), such as when you: Visit our website at
        skillglance.com, or any website of ours that links to this privacy
        notice. Reading this privacy notice will help you understand your
        privacy rights and choices. If you do not agree with our policies and
        practices, please do not use our Services. If you still have any
        questions or concerns, please contact us at support@skillglance.com.
      </p>

      <h2> SUMMARY OF KEY POINTS</h2>

      <p>
        What personal information do we process? When you visit, use, or
        navigate our Services, we may process personal information depending on
        how you interact with us and the Services, the choices you make, and the
        products and features you use. Learn more about personal information you
        disclose to us.
      </p>

      <p>
        Do we process any sensitive personal information? We do not process
        sensitive personal information.
      </p>

      <p>
        Do we receive any information from third parties? We do not receive any
        information from third parties.
      </p>

      <p>
        How do we process your information? We process your information to
        provide, improve, and administer our Services, communicate with you, for
        security and fraud prevention, and to comply with law. We may also
        process your information for other purposes with your consent. We
        process your information only when we have a valid legal reason to do
        so. Learn more about how we process your information.
      </p>

      <p>
        In what situations and with which parties do we share personal
        information? We may share information in specific situations and with
        specific third parties. Learn more about when and with whom we share
        your personal information.
      </p>

      <p>
        How do we keep your information safe? We have organizational and
        technical processes and procedures in place to protect your personal
        information. However, no electronic transmission over the internet or
        information storage technology can be guaranteed to be 100% secure, so
        we cannot promise or guarantee that hackers, cybercriminals, or other
        unauthorized third parties will not be able to defeat our security and
        improperly collect, access, steal, or modify your information. Learn
        more about how we keep your information safe.
      </p>

      <p>
        What are your rights? Depending on where you are located geographically,
        the applicable privacy law may mean you have certain rights regarding
        your personal information. Learn more about your privacy rights.
      </p>

      <p>
        How do you exercise your rights? The easiest way to exercise your rights
        is by contacting usbat support@skillglance.com. We will consider and act
        upon any request in accordance with applicable data protection laws.
      </p>

      <p>
        Want to learn more about what we do with any information we collect?
        Review the privacy notice in full.
      </p>

      <h2> TABLE OF CONTENTS</h2>

      <p>
        1. WHAT INFORMATION DO WE COLLECT?
        <br />
        2. HOW DO WE PROCESS YOUR INFORMATION?
        <br />
        3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        <br />
        4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        <br />
        5. HOW LONG DO WE KEEP YOUR INFORMATION?
        <br />
        6. HOW DO WE KEEP YOUR INFORMATION SAFE?
        <br />
        7. DO WE COLLECT INFORMATION FROM MINORS?
        <br />
        8. WHAT ARE YOUR PRIVACY RIGHTS?
        <br />
        9. CONTROLS FOR DO-NOT-TRACK FEATURES
        <br />
        10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        <br />
        11. DO WE MAKE UPDATES TO THIS NOTICE?
        <br />
        12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        <br />
        13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </p>

      <h2> 1. WHAT INFORMATION DO WE COLLECT?</h2>

      <p>Personal information you disclose to us</p>

      <p>In Short: We collect personal information that you provide to us.</p>

      <p>
        We collect personal information that you voluntarily provide to us when
        you register on the Services, express an interest in obtaining
        information about us or our Services, when you participate in activities
        on the Services, or otherwise when you contact us.
      </p>

      <p>
        Personal Information Provided by You. The personal information that we
        collect depends on the context of your interactions with us and the
        Services, the choices you make, and the features you use. The personal
        information we collect may include the following: email addresses
        passwords usernames resumes uploaded to the platform job descriptions
        uploaded to the platform. We do not process sensitive information.
      </p>

      <p>
        All personal information that you provide to us must be true, complete,
        and accurate, and you must notify us of any changes to such personal
        information.
      </p>

      <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>

      <p>
        In Short: We process your information to provide, improve, and
        administer our Services, communicate with you, for security and fraud
        prevention, and to comply with law. We may also process your information
        for other purposes with your consent.
      </p>

      <p>
        We process your personal information for a variety of reasons, depending
        on how you interact with our Services, including: To facilitate account
        creation and authentication and otherwise manage user accounts. We may
        process your information so you can create and log in to your account,
        as well as keep your account in working order.
      </p>

      <p></p>

      <p></p>

      <h2>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>

      <p>
        We may need to share your personal information with our database
        provider.
      </p>

      <h2>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>

      <p>
        In Short: We may use cookies and other tracking technologies to collect
        and store your information.
      </p>

      <p>
        We may use cookies and similar tracking technologies (like web beacons
        and pixels) to access or store information.
      </p>

      <h2>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>

      <p>
        In Short: We keep your information for as long as necessary to fulfill
        the purposes outlined in this privacy notice unless otherwise required
        by law.
      </p>

      <p>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law. No purpose in
        this notice will require us keeping your personal information for longer
        than the period of time in which users have an account with us.
      </p>

      <p>
        When we have no ongoing legitimate need to process your personal
        information, we will either delete or anonymize such information, or, if
        this is not possible (for example, because your personal information has
        been stored in backup archives), then we will securely store your
        personal information and isolate it from any further processing until
        deletion is possible.
      </p>

      <h2>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>

      <p>
        In Short: We aim to protect your personal information through a system
        of organizational and technical security measures.
      </p>

      <p>
        We have implemented appropriate and reasonable technical and
        organizational security measures designed to protect the security of any
        personal information we process. However, despite our safeguards and
        efforts to secure your information, no electronic transmission over the
        Internet or information storage technology can be guaranteed to be 100%
        secure, so we cannot promise or guarantee that hackers, cybercriminals,
        or other unauthorized third parties will not be able to defeat our
        security and improperly collect, access, steal, or modify your
        information. Although we will do our best to protect your personal
        information, transmission of personal information to and from our
        Services is at your own risk. You should only access the Services within
        a secure environment.
      </p>

      <h2>7. DO WE COLLECT INFORMATION FROM MINORS?</h2>

      <p>
        In Short: We do not knowingly collect data from or market to children
        under 18 years of age.
      </p>

      <p>
        We do not knowingly solicit data from or market to children under 18
        years of age. By using the Services, you represent that you are at least
        18 or that you are the parent or guardian of such a minor and consent to
        such minor dependent&rsquo;s use of the Services. If we learn that
        personal information from users less than 18 years of age has been
        collected, we will deactivate the account and take reasonable measures
        to promptly delete such data from our records. If you become aware of
        any data we may have collected from children under age 18, please
        contact us at support@skillglance.com.
      </p>

      <h2>8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>

      <p>
        In Short: You may review, change, or terminate your account at any time.
      </p>

      <p>
        Withdrawing your consent: If we are relying on your consent to process
        your personal information, which may be express and/or implied consent
        depending on the applicable law, you have the right to withdraw your
        consent at any time. You can withdraw your consent at any time by
        contacting us by using the contact details provided in the section
        &quot;HOW CAN YOU CONTACT US ABOUT THIS NOTICE?&quot; below.
      </p>

      <p>
        However, please note that this will not affect the lawfulness of the
        processing before its withdrawal nor, when applicable law allows, will
        it affect the processing of your personal information conducted in
        reliance on lawful processing grounds other than consent.
      </p>

      <p>Account Information</p>

      <p>
        If you would at any time like to review or change the information in
        your account or terminate your account, you can: Contact us using the
        contact information provided. Upon your request to terminate your
        account, we will deactivate or delete your account and information from
        our active databases. However, we may retain some information in our
        files to prevent fraud, troubleshoot problems, assist with any
        investigations, enforce our legal terms and/or comply with applicable
        legal requirements.
      </p>

      <p>
        Cookies and similar technologies: Most Web browsers are set to accept
        cookies by default. If you prefer, you can usually choose to set your
        browser to remove cookies and to reject cookies. If you choose to remove
        cookies or reject cookies, this could affect certain features or
        services of our Services. You may also opt out of interest-based
        advertising by advertisers on our Services.
      </p>

      <p>
        If you have questions or comments about your privacy rights, you may
        email us at support@skillglance.com.
      </p>

      <h2>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>

      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track (&quot;DNT&quot;) feature or setting
        you can activate to signal your privacy preference not to have data
        about your online browsing activities monitored and collected. At this
        stage no uniform technology standard for recognizing and implementing
        DNT signals has been finalized. As such, we do not currently respond to
        DNT browser signals or any other mechanism that automatically
        communicates your choice not to be tracked online. If a standard for
        online tracking is adopted that we must follow in the future, we will
        inform you about that practice in a revised version of this privacy
        notice.
      </p>

      <h2>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>

      <p>
        In Short: Yes, if you are a resident of California, you are granted
        specific rights regarding access to your personal information.
      </p>

      <p>
        California Civil Code Section 1798.83, also known as the &quot;Shine The
        Light&quot; law, permits our users who are California residents to
        request and obtain from us, once a year and free of charge, information
        about categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>

      <p>
        If you are under 18 years of age, reside in California, and have a
        registered account with Services, you have the right to request removal
        of unwanted data that you publicly post on the Services. To request
        removal of such data, please contact us using the contact information
        provided below and include the email address associated with your
        account and a statement that you reside in California. We will make sure
        the data is not publicly displayed on the Services, but please be aware
        that the data may not be completely or comprehensively removed from all
        our systems (e.g., backups, etc.).
      </p>

      <p>CCPA Privacy Notice</p>

      <p>
        The California Code of Regulations defines a &quot;resident&quot; as:
      </p>

      <p>
        (1) every individual who is in the State of California for other than a
        temporary or transitory purpose and (2) every individual who is
        domiciled in the State of California who is outside the State of
        California for a temporary or transitory purpose
      </p>

      <p>All other individuals are defined as &quot;non-residents.&quot;</p>

      <p>
        If this definition of &quot;resident&quot; applies to you, we must
        adhere to certain rights and obligations regarding your personal
        information.
      </p>

      <p>What categories of personal information do we collect?</p>

      <p>
        We have collected the following categories of personal information in
        the past twelve (12) months:
      </p>

      <p>
        Category Examples Collected A. Identifiers Contact details, such as real
        name, alias, postal address, telephone or mobile contact number, unique
        personal identifier, online identifier, Internet Protocol address, email
        address, and account name
      </p>

      <p>YES</p>

      <p>
        B. Personal information categories listed in the California Customer
        Records statute Name, contact information, education, employment,
        employment history, and financial information
      </p>

      <p>NO</p>

      <p>
        C. Protected classification characteristics under California or federal
        law Gender and date of birth
      </p>

      <p>NO</p>

      <p>
        D. Commercial information Transaction information, purchase history,
        financial details, and payment information
      </p>

      <p>NO</p>

      <p>E. Biometric information Fingerprints and voiceprints</p>

      <p>NO</p>

      <p>
        F. Internet or other similar network activity Browsing history, search
        history, online behavior, interest data, and interactions with our and
        other websites, applications, systems, and advertisements
      </p>

      <p>NO</p>

      <p>G. Geolocation data Device location</p>

      <p>NO</p>

      <p>
        H. Audio, electronic, visual, thermal, olfactory, or similar information
        Images and audio, video or call recordings created in connection with
        our activities
      </p>

      <p>NO</p>

      <p>
        I. Professional or employment-related information Business contact
        details in order to provide you our Services
      </p>

      <p>YES</p>

      <p>J. Education Information Student records and directory information</p>

      <p>YES</p>

      <p>
        K. Inferences drawn from other personal information Inferences drawn
        from any of the collected personal information listed above to create a
        profile or summary about, for example, an individual&rsquo;s preferences
        and characteristics
      </p>

      <p>NO</p>

      <p>L. Sensitive Personal Information NO</p>

      <p>
        {" "}
        We will use and retain the collected personal information as needed to
        provide the Services or for: Category A - As long as the user has an
        account with us Category I - As long as the user has an account with us
        Category J - As long as the user has an account with us We may also
        collect other personal information outside of these categories through
        instances where you interact with us in person, online, or by phone or
        mail in the context of: Receiving help through our user support
        channels; Participation in user surveys or contests; and Facilitation in
        the delivery of our Services and to respond to your inquiries.
      </p>

      <p>
        More information about our data collection and sharing practices can be
        found in this privacy notice.
      </p>

      <p>
        You may contact us by email at support@skillglance.com, or by referring
        to the contact details at the bottom of this document.
      </p>

      <p>
        If you are using an authorized agent to exercise your right to opt out
        we may deny a request if the authorized agent does not submit proof that
        they have been validly authorized to act on your behalf.
      </p>

      <p>Will your information be shared with anyone else?</p>

      <p>
        We may disclose your personal information with our service providers
        pursuant to a written contract between us and each service provider.
        Each service provider is a for-profit entity that processes the
        information on our behalf, following the same strict privacy protection
        obligations mandated by the CCPA.
      </p>

      <p>
        We may use your personal information for our own internal purposes, such
        as for undertaking internal research for technological development and
        demonstration. This is not considered to be &quot;selling&quot; of your
        personal information.
      </p>

      <p>
        We have not disclosed, sold, or shared any personal information to third
        parties for a business or commercial purpose in the preceding twelve
        (12) months. We will not sell or share personal information in the
        future belonging to website visitors and users.
      </p>

      <p>Your rights with respect to your personal data</p>

      <p>Right to request deletion of the data &mdash; Request to delete</p>

      <p>
        You can ask for the deletion of your personal information. If you ask us
        to delete your personal information, we will respect your request and
        delete your personal information, subject to certain exceptions provided
        by law, such as (but not limited to) the exercise by another user of his
        or her right to free speech, our compliance requirements resulting from
        a legal obligation, or any processing that may be required to protect
        against illegal activities.
      </p>

      <p>Right to be informed &mdash; Request to know</p>

      <p>
        Depending on the circumstances, you have a right to know: whether we
        collect and use your personal information; the categories of personal
        information that we collect; the purposes for which the collected
        personal information is used; whether we sell or share personal
        information to third parties; the categories of personal information
        that we sold, shared, or disclosed; the categories of third parties to
        whom the personal information was sold, shared, or disclosed; and the
        specific pieces of personal information we collected about you. In
        accordance with applicable law, we are not obligated to provide or
        delete user information that is de-identified in response to a user
        request or to re-identify individual data to verify a user request.
      </p>

      <p>
        Right to Non-Discrimination for the Exercise of a User&rsquo;s Privacy
        Rights
      </p>

      <p>
        We will not discriminate against you if you exercise your privacy
        rights.
      </p>

      <p>Right to Limit Use and Disclosure of Sensitive Personal Information</p>

      <p>We do not process user's sensitive personal information.</p>

      <p>Verification process</p>

      <p>
        Upon receiving your request, we will need to verify your identity to
        determine you are the same person about whom we have the information in
        our system. These verification efforts require us to ask you to provide
        information so that we can match it with information you have previously
        provided us. For instance, depending on the type of request you submit,
        we may ask you to provide certain information so that we can match the
        information you provide with the information we already have on file, or
        we may contact you through a communication method (e.g., phone or email)
        that you have previously provided to us. We may also use other
        verification methods as the circumstances dictate.
      </p>

      <p>
        We will only use personal information provided in your request to verify
        your identity or authority to make the request. To the extent possible,
        we will avoid requesting additional information from you for the
        purposes of verification. However, if we cannot verify your identity
        from the information already maintained by us, we may request that you
        provide additional information for the purposes of verifying your
        identity and for security or fraud-prevention purposes. We will delete
        such additionally provided information as soon as we finish verifying
        you.
      </p>

      <p>
        Other privacy rights You may object to the processing of your personal
        information. You may request correction of your personal data if it is
        incorrect or no longer relevant, or ask to restrict the processing of
        the information. You can designate an authorized agent to make a request
        under the CCPA on your behalf. We may deny a request from an authorized
        agent that does not submit proof that they have been validly authorized
        to act on your behalf in accordance with the CCPA. You may request to
        opt out from future selling or sharing of your personal information to
        third parties. Upon receiving an opt-out request, we will act upon the
        request as soon as feasibly possible, but no later than fifteen (15)
        days from the date of the request submission. To exercise these rights,
        you can contact us by email at support@skillglance.com, or by referring
        to the contact details at the bottom of this document. If you have a
        complaint about how we handle your data, we would like to hear from you.
      </p>

      <h2>11. DO WE MAKE UPDATES TO THIS NOTICE?</h2>

      <p>
        In Short: Yes, we will update this notice as necessary to stay compliant
        with relevant laws.
      </p>

      <p>
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated &quot;Revised&quot; date and the updated
        version will be effective as soon as it is accessible. If we make
        material changes to this privacy notice, we may notify you either by
        prominently posting a notice of such changes or by directly sending you
        a notification. We encourage you to review this privacy notice
        frequently to be informed of how we are protecting your information.
      </p>

      <h2>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>

      <p>
        If you have questions or comments about this notice, you may email us at
        support@skillglance.com
      </p>

      <h2>
        13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </h2>

      <p>
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it. To request to review, update, or delete
        your personal information, please visit: support@skillglance.com.
      </p>
    </div>
  );
}

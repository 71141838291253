import {useEffect, useRef, useContext, useState} from 'react'
import {Link, useParams} from "react-router-dom"
import {UserContext} from "../Context/UserContext"
import {EditScanContext} from "../Context/EditScanContext"
import {LeftBarContext} from "../Context/LeftBarContext"
import Editor from "../Editor"
import ScanResumeBar from "../ScanResumeBar"
import EditScanSectionDetails from "../EditScanSectionDetails"
import EditScanToolbar, { formats } from "../EditScanToolbar"
import ResultsMobile from '../ResultsMobile'
// import ReactQuill from "react-quill";
import Quill from 'quill'
// import {asBlob, htmlDocx} from 'html-docx-js'
import saveAs from 'file-saver'
import * as quillToWord from 'quill-to-word'
import { v4 as uuidv4 } from 'uuid';
import "./EditScanPage.css"

import Error from '../modals/Error'
import Success from '../modals/Success'
import Input from '../modals/Input'
import JobDesc from '../modals/JobDesc'
import Loading from "../Loading"
import DelayButton from '../Button/DelayButton'


export default function EditScan(){
    const {id} = useParams() //access the parameters of the current URL
    const {status, userInfo, setStatus, setUserInfo} = useContext(UserContext)
    const {setLock, setSelected} = useContext(LeftBarContext)
    const {jobId, setJobId, jobTitle, setJobTitle, company, setCompany, jobDesc, setJobDesc, jobStatus, setJobStatus, logo, setLogo, resume, results, setResults, lastUpdateTime, isUpdateLoading, setIsUpdateLoading} = useContext(EditScanContext)
    const editorRef = useRef(null);
    const toolbarRef = useRef(null);
    const [initialResume, setInitialResume] = useState(null)

    const [errorMsg, setErrorMsg] = useState(false)
    const [editCompanyError, setEditCompanyError] = useState("") // from server not client side validation
    const [showEditCompanyError, setShowEditCompanyError] = useState(false)

    const [successMsg, setSuccessMsg] = useState(false)
    const [inputMsg, setInputMsg] = useState(false)
    const [jobDescMsg, setJobDescMsg] = useState(false)
    const [newCompany, setNewCompany] = useState("")
    const [editCompanyLoading, setEditCompanyLoading] = useState(false)
    const [saveTemplateLoading, setSaveTemplateLoading] = useState(false)

    const saveTemplateRequestId = useRef(null)

    const jobCompanyLimit = 200 //double check limit with backend


    useEffect(() => {
        setLock(false)
        setSelected("scan")
        const fetchScan = async () =>{
            const response = await fetch(process.env.REACT_APP_API_URL + `/scan/${id}`, {
            credentials: 'include',})
            if (response.ok) {
                response.json().then(scanData => {
                    setInitialResume(scanData.resume)
                    setJobId(scanData.job._id)
                    setJobTitle(scanData.job.title)
                    setCompany(scanData.job.company)
                    setJobDesc(scanData.job.description)
                    setJobStatus(scanData.job.status)
                    // setLogo(scanData.job.logo)
                    setResults(scanData.results)
                })
            } else{
                const message = await response.json();
                if (message === "not logged in"){
                    setStatus("not logged in")
                    setUserInfo(null)
                } else {
                    console.log("no edit privilege")
                }
            }
        }
        fetchScan()
    }, [])

    // runs on initial render and value initialResume change
    useEffect(() => {
        // Check if the div we are referencing is in the DOM
        if (initialResume && editorRef.current && toolbarRef.current) {

            const editor = new Quill(editorRef.current, {
                modules: {
                    toolbar: {
                      container: toolbarRef.current,
                      handlers: {
                        undo: undoChange,
                        redo: redoChange
                      }
                    },
                    history: {
                      delay: 500,
                      maxStack: 100,
                      userOnly: true
                    }
                },
                formats: formats,
                theme: 'snow',
                style: '.ql-toolbar { background-color: #eee; }',
                // prevent scrolling on paste
                scrollingContainer: '#editorContainer'
            });

            // console.log(formats)
            console.log("ready")

            // prevent scroll to top on style change
            toolbarRef.current.addEventListener("mousedown", function(event) {

                event.preventDefault()
                event.stopPropagation()
            })

            const delta = editor.clipboard.convert(initialResume)
            editor.setContents(delta)

            // //incase we want to download before editing
            // resumeDelta.current = editor.getContents()

            //incase we want to save as template before editing
            resume.current = editor.root.innerHTML
            
            editor.on('text-change', () => {
                // useRef always get the most recent resume unlike useState 
                // which will rerender and the old render will have the old value even after 3 seconds
                resume.current = editor.root.innerHTML

                // resumeDelta.current = editor.getContents()
                setIsUpdateLoading(true)

                const now = new Date()
                // wait 2 seconds before sending request, next request can be queued
                // once the previous one is sent
                if ( !lastUpdateTime.current || now - lastUpdateTime.current > 2000){
                    console.log("start queuing")
                    lastUpdateTime.current = now
                    console.log(now)
                    console.log(lastUpdateTime.current)
                    const timeout = setTimeout(() => {
                        //send request
                        console.log("send request")
                        scanResume()
                    }, 2000)
                }
            })
        }
    }, [initialResume])

    // send request time and get back request time
    // useState is async but does NOT return promise so await won't work
    // also useState will cause re-render and in the new render it will have new values 
    // but the current one won't, potentially causing issues
    // will use mostRecentResume not resume, as resume will still be 
    // value from 3 seconds ago because it is from a previous render
    async function scanResume() {
        const response = await fetch(process.env.REACT_APP_API_URL + "/scan", {
            method: 'PUT',
            body: JSON.stringify({id, resume: resume.current, lastUpdateTime: lastUpdateTime.current}),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        if (response.ok) {
            const scanInfo = await response.json()
            setResults(scanInfo)
            const serverResponseDate = new Date(scanInfo.lastUpdateTime)
            // must use .getTime() or will compare if it is the same OBJECT
            if (serverResponseDate.getTime() === lastUpdateTime.current.getTime()){
                setIsUpdateLoading(false)
                console.log("up to date")
            }
        } else {
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
        }
    }

    // Undo and redo functions for Custom Toolbar
    function undoChange() {
        // "this" is caller, aka toolbar
        this.quill.history.undo();
    }
    function redoChange() {
        this.quill.history.redo();
    }

    async function downloadResume() {    
        window.print();
    }

    function checkCompanyError(value) {
        if ( !isNotAllSpaces(value) ){
            return "Company name cannot be empty."
        }

        if ( value.length > jobCompanyLimit){
            return `Input exceeds maximum character limit of ${jobCompanyLimit}.`
        }

        return ""
    }


    async function editCompany() {
        setEditCompanyLoading(true)
        setShowEditCompanyError(false)
        const response = await fetch(process.env.REACT_APP_API_URL + '/company', {
            method: 'PUT',
            body: JSON.stringify({jobId: jobId, company: newCompany}),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        if (response.ok) {
            const jobInfo = await response.json()
            setCompany(jobInfo.company)
            setEditCompanyLoading(false)
            setNewCompany("")
            setInputMsg(null)
        }else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setEditCompanyLoading(false)
                // setNewCompany("")
                // setInputMsg(null)
                // setErrorMsg({title: "Oops", desc: message})
                setEditCompanyError(message)
                setShowEditCompanyError(true)
            }
        }
    }

    async function saveAsTemplate(){
        setSaveTemplateLoading(true)

        const requestId = uuidv4()
        saveTemplateRequestId.current = requestId

        const response = await fetch(process.env.REACT_APP_API_URL + '/resume', {
            method: 'POST',
            body: JSON.stringify({requestId: requestId, content: resume.current, title: `Resume for ${jobTitle} at ${company}`}),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        if (response.ok) {
            const data = await response.json();
            if (data.requestId === saveTemplateRequestId.current){
                setSuccessMsg({title: "Success", desc: `"${data.templateDoc.title}" saved as new resume`})
                setSaveTemplateLoading(false)
            }
        }else{
            const data = await response.json();
            const message = data.message;
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setSaveTemplateLoading(false)
                setErrorMsg({title: "Oops", desc: message})
            }
        }
    }

    const handleFocus = (event) => {
        event.preventDefault()
        console.log("focus")
    }

    const handleBlur = (event) => {
        event.preventDefault()
        console.log("blur")
    }

    function isNotAllSpaces(inputString) {
        // Use a regular expression to check if the string contains at least one non-space character
        return /\S/.test(inputString);
    }

    return (
        initialResume && jobId && jobTitle && company && results? <>
            <Success successMsg={successMsg} setSuccessMsg={setSuccessMsg} buttonText="Okay"/>
            <Error errorMsg={errorMsg} setErrorMsg={setErrorMsg} />
            <Input inputMsg={inputMsg} setInputMsg={setInputMsg} buttonText="Confirm" value={newCompany} setValue={setNewCompany} placeholder="Company Name" customClick={editCompany} loading={editCompanyLoading} setLoading={setEditCompanyLoading} CheckError={checkCompanyError} bottomError={editCompanyError} showBottomError={showEditCompanyError} setShowBottomError={setShowEditCompanyError} />
            <JobDesc inputMsg={jobDescMsg} setInputMsg={setJobDescMsg} />
            <div className='mobile-only-result'>
                {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="desktop-only-icon">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                </svg>
                <div className='desktop-only-title'>Better on Desktop</div>
                <div className='desktop-only-text'>Sorry, Live Edit is currently only available on desktops or larger screens</div> */}
                <ResultsMobile jobTitle={jobTitle} />
            </div>
            <div className='edit-scan-container'>
                <div className='edit-scan-header'>
                    <div className='edit-scan-header-left'>
                        <Link to="/history" className='edit-scan-header-back'>
                            <svg id="edit-scan-header-arrow" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                            </svg>
                        </Link>
                        <div className='edit-scan-header-left-main'>
                            Target job
                            <div className='edit-scan-header-title-wrapper'>
                                <div className='edit-scan-header-title' onClick={() => {setJobDescMsg({jobDesc, jobTitle, jobStatus, jobId})}}>{jobTitle}</div>
                            </div> 
                            <div className='edit-scan-header-company-container'>
                                <div className='edit-scan-header-company'>{company}</div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" 
                                    className="edit-scan-header-company-edit"
                                    onClick={() => {setInputMsg({title: "Edit Company Name", desc: ""})}}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className='edit-scan-header-right'>
                        {/* <button className='edit-scan-header-save-template' onClick={() => saveAsTemplate()}>Save as Template</button> */}
                        <DelayButton buttonClassName="edit-scan-save-template" buttonClassNameLoading="edit-scan-save-template-loading" isLoading={saveTemplateLoading} onClick={() => saveAsTemplate()}>{"Save as Resume"}</DelayButton>
                        <button className='edit-scan-header-download' onClick={() => downloadResume()}>Download</button>
                        <Link to="/settings" className='edit-scan-account-circle'>
                            <div className='edit-scan-account-text'>{userInfo.username[0].toUpperCase()}</div>
                        </Link>
                    </div>
                </div>
                <div className='edit-scan-main'>
                    <div className='edit-scan-main-left'>
                        <div className='edit-scan-main-left-toolbar'>
                            <div ref={toolbarRef} ><EditScanToolbar /></div>
                        </div>
                        <div className='edit-scan-main-left-content'>
                            <div className='edit-scan-editor-wrapper' id="editorContainer">
                                <div className='edit-scan-editor-container' >
                                    {/* points and # will always be the size 10 by quill design */}
                                    <div ref={editorRef}
                                        style={{height:"100%", border:"none", color:"#333", backgroundColor:"white"}} >
                                    </div>
                                </div>
                            </div>
                            <EditScanSectionDetails/>
                        </div>
                    </div>
                    <ScanResumeBar />
                </div>
            </div>
        </> : <Loading text="Loading your scan"/>
    )
}
import {useEffect, useContext, useState} from 'react'
import { UserContext } from '../Context/UserContext'
import {LeftBarContext} from "../Context/LeftBarContext"
import {formatISO9075, roundToNearestMinutes} from "date-fns"
import {Link} from "react-router-dom" 
import StatBox from '../StatBox'
import './DashboardPage.css'
import History from "../History"
import StandardCoin from '../images/standard_coin.svg';
import PremiumCoin from '../images/premium_coin.svg';
import Loading from "../Loading"
import Event from "../Event"
import Message from "../Message"

export default function DashboardPage() {
    const { userInfo } = useContext(UserContext)
    const {setLock, setSelected} = useContext(LeftBarContext)
    const [summary, setSummary] = useState(null)
    const [membership, setMembership] = useState("")
    const [progressValue, setProgressValue] = useState(0)
    const { setStatus, setUserInfo} = useContext(UserContext);



    useEffect(() => {
        setLock(false)
        setSelected("dashboard")

        // fetch and json is async
        const fetchData = async () =>{
            const response = await fetch(process.env.REACT_APP_API_URL + '/dashboard', {credentials: 'include',})
            if (response.ok) {
                response.json().then(dashboardSummary => {
                    setSummary(dashboardSummary)
                    if (new Date(dashboardSummary.premium_expires) >= new Date()) {
                        setMembership("Premium Member")
                    } else {
                        setMembership("Skillglance Member")
                    }
                })
            } else{
                const message = await response.json();
                if (message === "not logged in"){
                    setStatus("not logged in")
                    setUserInfo(null)
                }
            }
        }
        fetchData()
    }, []);

    function DaysAgo(date) {
        const createdAtDate = new Date(date)
        const currentDate = new Date()

        // Calculate the time difference in milliseconds
        const timeDifference = currentDate - createdAtDate;
      
        // Calculate the number of days
        const daysAgo = Math.max(0, Math.floor(timeDifference / (1000 * 60 * 60 * 24)))

        if (daysAgo === 1){
            return "1 day ago"
        } else if (daysAgo < 100){
            return daysAgo + " days ago"
        } else {
            return "99+ days ago"
        }
    }

    function getDateFromString(dateString){
        const dateObject = new Date(dateString);
        const options = {
            day: "numeric",
            month: "long",
        };
        return dateObject.toLocaleDateString(undefined, options)
    }

    return (
        summary ? 
        <div className='dashboard'>
            <div className='main'>
                <div className='main-inner'>
                    <div className='title'>Dashboard</div>
                    <div className='user-info-mobile'>
                        <div className="dashboard-profile-info">
                            <div className={`dashboard-membership-icon ${membership === "Premium Member" && "premium"}`}>
                                {userInfo.username[0].toUpperCase()}</div>
                            
                            <div className="dashboard-membership-member">
                                <div className="dashboard-member-name">{userInfo.username}</div>
                                <div className="dashboard-member-status">{membership}</div>
                            </div>
                        </div>
                        <div className="dashboard-messages-mobile">
                            <div className="dashboard-events-title-mobile">
                                Upcoming Tasks
                            </div>
                            <div className="events">
                                {summary.events.length > 0 ? summary.events.map((event, index) => (
                                    <Event {...event} key={event._id} dashboard={true}/>
                                )) : 
                                <div className="empty-message">
                                    No tasks
                                </div>    
                                }

                            </div> 
                            <div className="dashboard-events-title-mobile">
                                Messages
                            </div>
                            <div className="events">
                                {summary.messages.length > 0 ? summary.messages.map((messages, index) => (
                                    <Message {...messages} key={messages._id} dashboard={true}/>
                                )) : 
                                <div className="empty-message">
                                    No messages
                                </div>                        
                                }
                            </div> 
                        </div>
                    </div>
                    <div className='stats'>
                        <div className='stats-row'>
                            <StatBox icon="newScans" used={summary.scanStats.used} 
                                limit={summary.scanStats.limit} statDesc="new scans this week" linkTo="/scan"
                                tooltip={`Renews ${getDateFromString(summary.scanStats.rechargeDate)}`}
                            />
                            <StatBox icon="histories" used={summary.historyStats.used} 
                                limit={summary.historyStats.limit} statDesc="total scan histories stored" linkTo="/history"/>
                        </div>
                        <div className='stats-row'>
                            <StatBox icon="resumes" used={summary.spaceStats.used} 
                                limit={summary.spaceStats.limit} statDesc="total resumes created" linkTo="/resumes"/>
                            <StatBox icon="jobs" used={summary.jobStats.used} 
                                limit={summary.jobStats.limit} statDesc="total applications tracked" linkTo="/jobs"/>
                        </div>
                    </div>
                    <div className='middle'>
                        <div className='histories'>
                            <div className='histories-top'>
                                Scans
                                <Link className='histories-more' to={"/history"}>More</Link>
                            </div>
                            <div className='histories-main'>
                                {summary.histories.length > 0 ? summary.histories.map(history => (
                                    <History {...history} key={history._id} dashboard={true}/>
                                )) : 
                                <div className="empty">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="empty-icon">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                                    </svg>
                                    No scans
                                </div>}
                            </div>
                        </div>
                        <div className='templates'>
                            <div className='templates-top'>
                                Resumes
                                <Link className='templates-more' to={"/resumes"}>More</Link>
                            </div>

                            <div className="templates-main">
                                <div className="select-template-table-keys">
                                    <div className="template-title-key">Title</div>
                                    <div className="template-update-time">Updated</div> 
                                </div>
                                <div className="select-template-main-scroll">
                                    {summary.templates.length > 0 && summary.templates.map(template => (
                                        <Link className="file" key={template._id} to={`/resume/${template._id}`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="file-icon">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                            </svg>
                                            <div className="template-title">{template.title}</div>
                                            <div className="template-update-time">{DaysAgo(template.updatedAt)}</div>                                
                                        </Link>
                                    ))}

                                    {!summary.templates.length && <div className="empty">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="empty-icon">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                                        </svg>
                                        No resumes
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='right-bar'>
                <div className="dashboard-profile-info">
                    {/* {membership === "Premium Member" ? <img src={PremiumCoin} alt="Premium Member" className="dashboard-membership-icon"/> :
                    <img src={StandardCoin} alt="Standard Member" className="dashboard-membership-icon"/>} */}

                    <Link className={`dashboard-membership-icon ${membership === "Premium Member" && "premium"}`} to="/settings"> 
                        {userInfo.username[0].toUpperCase()}</Link>
                    
                    <div className="dashboard-membership-member">
                        <div className="dashboard-member-name">{userInfo.username}</div>
                        <div className="dashboard-member-status">{membership}</div>
                    </div>
                </div>
                <div className="messages">
                    <div className="dashboard-events-title">
                        Upcoming Tasks
                    </div>
                    <div className="events">
                        {summary.events.length > 0 ? summary.events.map((event, index) => (
                            <Event {...event} key={event._id} dashboard={true}/>
                        )) : 
                        <div className="empty-message">
                            No tasks
                        </div>    
                        }

                    </div> 
                    <div className="dashboard-events-title">
                        Messages
                    </div>
                    <div className="events">
                        {summary.messages.length > 0 ? summary.messages.map((messages, index) => (
                            <Message {...messages} key={messages._id} dashboard={true}/>
                        )) : 
                        <div className="empty-message">
                            No messages
                        </div>                        
                        }
                    </div> 
                </div>
            </div>
        </div> : <Loading text="Loading your dashboard"/>
    )
}